import Rebase from "re-base";
import firebase from "firebase/app";
import "firebase/database";

// Modifier les valeurs en XXXX
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyByS54JDQVjrICFc3V7dbrxXLGBiwaSgo4",
  authDomain: "chatbox-app-4d683.firebaseapp.com",
  databaseURL: "https://chatbox-app-4d683.firebaseio.com",
});

const base = Rebase.createClass(firebase.database());

export { firebaseApp };

export default base;
